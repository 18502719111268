import SignIn from './views/signin/SignIn.vue'
import SignUp from './views/signin/SignUp.vue'
import SignOut from './views/signin/SignOut.vue'
import PasswordReset from './views/signin/PasswordReset.vue'
import PasswordForgot from './views/signin/PasswordForgot.vue'
import UserConfirmation from './views/signin/UserConfirmation.vue'
import ViewAuthenticated from '@/views/ViewAuthenticated.vue'
import DashboardsRouter from './views/dashboards/DashboardsRouter.vue'
import DashboardsIndex from './views/dashboards/DashboardsIndex.vue'
import RadiologyDashboard from './views/dashboards/RadiologyDashboard.vue'
import MarketShareRouter from '@/views/market-share/ViewMarketShareRouter.vue'
import MarketShareIndex from '@/views/market-share/ViewMarketShareIndex.vue'
import ViewNoAccess from '@/views/ViewNoAccess.vue'

export const routes = [
    { path: '/', redirect: '/home' },

    { path: '/sign-in', component: SignIn, meta: { title: 'Sign In' } },
    { path: '/sign-up', component: SignUp, meta: { title: 'Sign Up' } },
    { path: '/sign-out', component: SignOut, meta: { title: 'Sign Out' } },
    {
        path: '/user-confirmation',
        component: UserConfirmation,
        meta: { title: 'Sign Out' },
    },
    { path: '/reset-password', component: PasswordReset },
    { path: '/forgot-password', component: PasswordForgot },
    { path: '/no-access', component: ViewNoAccess, meta: { title: 'NoAccess' } },

    {
        path: '/docs',
        component: () => import('@/views/docs/ViewDocsRouter.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/docs/ViewDocsIndex.vue'),
                name: 'docs-index',
            },

            {
                path: 'keys',
                component: () => import('@/views/docs/keys/ViewDocsKeysRouter.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('@/views/docs/keys/ViewDocsKeysIndex.vue'),
                        name: 'docs-keys-index',
                    },
                ],
            },

            {
                path: 'apis',
                component: () => import('@/views/docs/apis/ViewDocsApisRouter.vue'),
                children: [
                    {
                        path: '',
                        name: 'docs-apis',
                        redirect: {
                            name: 'docs-apis-viewer',
                            params: {
                                id: 'REIMBURSEMENTS',
                            },
                        },
                    },
                    {
                        path: ':id/:env',
                        component: () => import('@/views/docs/apis/ViewDocsApisIndex.vue'),
                        children: [
                            {
                                path: '',
                                name: 'docs-apis-viewer',
                            },
                            {
                                path: ':section?',
                                name: 'docs-apis-viewer-section',
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        path: '/delivery',
        component: () => import('@/views/delivery/ViewDeliveryRouter.vue'),
        children: [
            {
                path: ':deliveryId',
                name: 'delivery',
                component: () => import('@/views/delivery/ViewDelivery.vue'),
            },
        ],
    },

    {
        path: '/sample',
        component: () => import('@/views/sample/ViewSampleRouter.vue'),
        children: [
            {
                path: '',
                redirect: {
                    name: 'sample-data',
                },
            },
            {
                path: 'data',
                component: () => import('@/views/sample/Data/ViewSampleDataRouter.vue'),
                children: [
                    {
                        path: '',
                        name: 'sample-data',
                        component: () => import('@/views/sample/Data/Index/ViewSampleDataIndex.vue'),
                    },
                    {
                        path: 'amplitude',
                        name: 'sample-data-amplitude',
                        component: () => import('@/views/sample/Data/Amplitude/ViewSampleDataAmplitude.vue'),
                    },
                    {
                        path: 'signal',
                        children: [
                            {
                                path: '',
                                redirect: 'sample-data-signal-search',
                            },
                            {
                                path: 'mrf-search',
                                name: 'sample-data-signal-search',
                                component: () =>
                                    import('@/views/sample/Data/Signal/Search/ViewSampleDataSignalSearch.vue'),
                            },
                            {
                                path: 'market-benchmarks',
                                name: 'sample-data-signal-market-benchmarks',
                                component: () =>
                                    import(
                                        '@/views/sample/Data/Signal/MarketBenchmarks/ViewSampleDataSignalMarketBenchmarks.vue'
                                    ),
                            },
                            {
                                path: 'peer-benchmarks',
                                name: 'sample-data-signal-peer-benchmarks',
                                component: () =>
                                    import(
                                        '@/views/sample/Data/Signal/PeerBenchmarks/ViewSampleDataSignalPeerBenchmarks.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        path: 'custom-pull',
                        name: 'sample-data-custom-pull',
                    },
                    {
                        path: 'methodology',
                        name: 'sample-data-methodology',
                    },
                ],
            },
            {
                path: 'custom-pull',
                component: () => import('@/views/sample/CustomPull/ViewSampleCustomPullRouter.vue'),
                children: [
                    {
                        path: '',
                        name: 'sample-custom-pull',
                        component: () => import('@/views/sample/CustomPull/Index/ViewSampleCustomPullIndex.vue'),
                    },
                ],
            },
            {
                path: 'sign-up',
                name: 'sample-signup',
                component: () => import('@/views/sample/ViewSampleSignUp.vue'),
            },
        ],
    },
    {
        path: '',
        components: {
            default: ViewAuthenticated,
            modalProviderSearch: () => import('@/views/provider-search/ViewProviderSearch.vue'),
        },
        children: [
            { path: '', redirect: '/home' },
            {
                path: 'home',
                component: () => import('@/views/Home/ViewHome.vue'),
                meta: { title: 'home' },
            },
            {
                path: 'accounts',
                component: () => import('@/views/accounts/ViewAccountsRouter.vue'),
                children: [
                    {
                        path: '',
                        name: 'accounts-index',
                        components: {
                            default: () => import('@/views/accounts/index/ViewAccountsIndex.vue'),
                            heading: () => import('@/views/accounts/index/ViewAccountsIndexHeading.vue'),
                            modalCreate: () => import('@/views/accounts/record/ViewAccountsRecordCreate.vue'),
                        },
                    },
                    {
                        path: ':accountId',
                        components: {
                            default: () => import('@/views/accounts/record/ViewAccountsRecordRouter.vue'),
                            heading: () => import('@/views/accounts/record/ViewAccountsRecordHeading.vue'),
                            modalEdit: () => import('@/views/accounts/record/ViewAccountsRecordEdit.vue'),
                            modalDelete: () => import('@/views/accounts/record/ViewAccountsRecordDelete.vue'),
                        },
                        meta: {
                            title: 'Account Details',
                        },
                        children: [
                            {
                                path: '',
                                name: 'accounts-record',
                                redirect: {
                                    name: 'accounts-record-pulls',
                                },
                            },
                            {
                                path: 'pulls',
                                components: {
                                    leftColumn: () =>
                                        import(
                                            '@/views/accounts/record/targets/ViewAccountsRecordTargetsCollection.vue'
                                        ),
                                    default: () =>
                                        import('@/views/accounts/record/pulls/ViewAccountsRecordPullsRouter.vue'),
                                    modalTargetCreate: () =>
                                        import('@/views/accounts/record/targets/ViewAccountsRecordTargetsCreate.vue'),
                                    modalTargetEdit: () =>
                                        import('@/views/accounts/record/targets/ViewAccountsRecordTargetsEdit.vue'),
                                    modalTargetDelete: () =>
                                        import('@/views/accounts/record/targets/ViewAccountsRecordTargetsDelete.vue'),
                                    modalPullDelete: () =>
                                        import('@/views/accounts/record/pulls/ViewAccountsRecordPullsDelete.vue'),
                                    modalPullReRun: () =>
                                        import('@/views/accounts/record/pulls/ViewAccountsRecordPullsReRun.vue'),
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'accounts-record-pulls',
                                        components: {
                                            list: () =>
                                                import(
                                                    '@/views/accounts/record/pulls/ViewAccountsRecordPullsIndex.vue'
                                                ),
                                        },
                                    },
                                    {
                                        path: 'create',
                                        name: 'accounts-record-pulls-create',
                                        components: {
                                            list: () =>
                                                import(
                                                    '@/views/accounts/record/pulls/ViewAccountsRecordPullsCreate.vue'
                                                ),
                                            default: () =>
                                                import(
                                                    '@/views/accounts/record/pulls/ViewAccountsRecordPullsNetworks.vue'
                                                ),
                                        },
                                    },
                                    {
                                        path: ':pullId',
                                        name: 'accounts-record-pulls-record',
                                        components: {
                                            list: () =>
                                                import(
                                                    '@/views/accounts/record/pulls/ViewAccountsRecordPullsIndex.vue'
                                                ),
                                            default: () =>
                                                import(
                                                    '@/views/accounts/record/pulls/ViewAccountsRecordPullsRecord.vue'
                                                ),
                                        },
                                    },
                                ],
                            },

                            {
                                path: 'deliveries',
                                components: {
                                    leftColumn: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/defaults/ViewAccountsRecordDeliveriesDefaults.vue'
                                        ),
                                    default: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/ViewAccountsRecordDeliveriesRouter.vue'
                                        ),
                                    modalDefaults: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/defaults/ViewAccountsRecordDeliveriesDefaultsEdit.vue'
                                        ),
                                    modalDefaultsDelete: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/defaults/ViewAccountsRecordDeliveriesDefaultsDelete.vue'
                                        ),
                                    modalDeliveryCreate: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/ViewAccountsRecordDeliveriesCreate.vue'
                                        ),
                                    modalDeliveryDataAdd: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/record/ViewAccountsRecordDeliveriesRecordDataAdd.vue'
                                        ),
                                    modalDeliveryDataEdit: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/record/ViewAccountsRecordDeliveriesRecordDataEdit.vue'
                                        ),
                                    modalDeliveryNotify: () =>
                                        import(
                                            '@/views/accounts/record/deliveries/record/ViewAccountsRecordDeliveriesRecordNotify.vue'
                                        ),
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'accounts-record-deliveries',
                                        components: {
                                            list: () =>
                                                import(
                                                    '@/views/accounts/record/deliveries/ViewAccountsRecordDeliveriesIndex.vue'
                                                ),
                                        },
                                    },

                                    {
                                        path: ':deliveryId',
                                        name: 'accounts-record-deliveries-record',
                                        components: {
                                            list: () =>
                                                import(
                                                    '@/views/accounts/record/deliveries/ViewAccountsRecordDeliveriesIndex.vue'
                                                ),
                                            default: () =>
                                                import(
                                                    '@/views/accounts/record/deliveries/record/ViewAccountsRecordDeliveriesRecord.vue'
                                                ),
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'configuration',
                                name: 'accounts-record-configuration',
                                components: {
                                    default: () =>
                                        import(
                                            '@/views/accounts/record/config/index/ViewAccountsRecordConfigIndex.vue'
                                        ),
                                },
                            },
                            {
                                path: 'products',
                                name: 'accounts-record-products',
                                components: {
                                    leftColumn: () =>
                                        import(
                                            '@/views/accounts/record/Products/ViewAccountsRecordProductsLeftColumn.vue'
                                        ),
                                    default: () =>
                                        import('@/views/accounts/record/Products/ViewAccountsRecordProducts.vue'),
                                },
                            },
                            {
                                path: 'users',
                                name: 'accounts-record-users',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'sources',
                components: {
                    default: () => import('@/views/sources/SourcesRouter.vue'),
                },
                children: [
                    {
                        path: '',
                        name: 'sources',
                        redirect: {
                            name: 'sources-payors-index',
                        },
                    },
                    {
                        path: 'ingest-report',
                        components: {
                            default: () => import('@/views/sources/ingest-report/ViewSourcesIngestReportRouter.vue'),
                            modalIndexTemplate: () => import('@/views/index-template/IndexTemplateEdit.vue'),
                            modalNetworkTemplate: () => import('@/views/network-template/NetworkTemplateEdit.vue'),
                        },
                        children: [
                            {
                                path: '',
                                name: 'sources-ingest-report-index',
                                redirect: {
                                    name: 'sources-ingest-report-payer',
                                },
                            },
                            {
                                path: 'payers',
                                name: 'sources-ingest-report-payers',
                                components: {
                                    default: () =>
                                        import(
                                            '@/views/sources/ingest-report/payers/index/ViewSourcesIngestReportPayersIndex.vue'
                                        ),
                                    heading: () =>
                                        import(
                                            '@/views/sources/ingest-report/payers/index/ViewSourcesIngestReportPayersIndexHeading.vue'
                                        ),
                                },
                            },
                            {
                                path: 'health-systems',
                                name: 'sources-ingest-report-health-systems',
                                components: {
                                    default: () =>
                                        import(
                                            '@/views/sources/ingest-report/health-systems/index/ViewSourcesIngestReportHealthSystemsIndex.vue'
                                        ),
                                    heading: () =>
                                        import(
                                            '@/views/sources/ingest-report/health-systems/index/ViewSourcesIngestReportHealthSystemsIndexHeading.vue'
                                        ),
                                },
                                query: {
                                    isAuto: true,
                                    status: 'ERROR',
                                },
                            },
                        ],
                    },
                    {
                        path: 'audits',
                        components: {
                            default: () => import('@/views/sources/audits/ViewSourcesAuditsRouter.vue'),
                        },
                        children: [
                            {
                                path: '',
                                name: 'sources-audits-index',
                                component: () => import('@/views/sources/audits/index/ViewSourcesAuditsIndex.vue'),
                            },
                            {
                                path: 'zombie-comparisons',
                                components: {
                                    default: () =>
                                        import(
                                            '@/views/sources/audits/zombie-comparisons/ViewSourcesAuditsZombieComparisons.vue'
                                        ),
                                },
                            },
                            {
                                path: 'network/:networkId',
                                name: 'sources-audits-network-record',
                                components: {
                                    default: () =>
                                        import('@/views/sources/audits/network/record/ViewSourcesNetworksRecord.vue'),
                                    heading: () =>
                                        import(
                                            '@/views/sources/audits/network/record/ViewSourcesNetworksRecordHeading.vue'
                                        ),
                                    modalNetworkEdit: () =>
                                        import('@/views/sources/audits/network/record/NetworksRecordEdit.vue'),
                                    modalComment: () => import('@/views/comments/ViewCommentsCreate.vue'),
                                    modalNetworkTemplate: () =>
                                        import('@/views/network-template/NetworkTemplateEdit.vue'),
                                },
                            },
                        ],
                    },
                    // {
                    //     path: 'tpas',
                    //     component: () => import('@/views/sources/third-party-administrators/SourcesTPAsRouter.vue'),
                    //     children: [
                    //         {
                    //             path: '',
                    //             name: 'sources-tpas-index',
                    //             components: {
                    //                 default: () =>
                    //                     import('@/views/sources/third-party-administrators/SourcesTPAsIndex.vue'),
                    //                 heading: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsIndexHeading.vue'
                    //                     ),
                    //                 modalCreate: () =>
                    //                     import('@/views/sources/third-party-administrators/SourcesTPAsCreate.vue'),
                    //             },
                    //             meta: {
                    //                 title: 'TPAs',
                    //             },
                    //         },
                    //         {
                    //             path: ':tpaId',
                    //             components: {
                    //                 default: () =>
                    //                     import('@/views/sources/third-party-administrators/SourcesTPAsRecord.vue'),
                    //                 heading: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsRecordHeading.vue'
                    //                     ),
                    //                 modalEdit: () =>
                    //                     import('@/views/sources/third-party-administrators/SourcesTPAsRecordEdit.vue'),
                    //                 modalDelete: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsRecordDelete.vue'
                    //                     ),
                    //                 modalCreateMetaFile: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsMetaFileCreate.vue'
                    //                     ),
                    //                 modalEditMetaFile: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsMetaFileEdit.vue'
                    //                     ),
                    //                 modalDeleteMetaFile: () =>
                    //                     import(
                    //                         '@/views/sources/third-party-administrators/SourcesTPAsMetaFileDelete.vue'
                    //                     ),
                    //             },
                    //             meta: {
                    //                 title: 'TPA Details',
                    //             },
                    //             children: [
                    //                 {
                    //                     path: '',
                    //                     name: 'sources-tpas-record',
                    //                     redirect: {
                    //                         name: 'sources-tpas-record-comments',
                    //                     },
                    //                 },
                    //                 {
                    //                     path: 'comments',
                    //                     name: 'sources-tpas-record-comments',
                    //                     component: () =>
                    //                         import(
                    //                             '@/views/sources/third-party-administrators/SourcesTPAsRecordComments.vue'
                    //                         ),
                    //                 },
                    //             ],
                    //         },
                    //     ],
                    // },

                    {
                        path: 'index-templates/:indexTemplateId',
                        components: {
                            default: () => import('@/views/index-template/IndexTemplate.vue'),
                        },
                    },
                    {
                        path: 'payors',
                        component: () => import('@/views/sources/payors/SourcesPayorsRouter.vue'),
                        children: [
                            {
                                path: '',
                                name: 'sources-payors-index',
                                components: {
                                    default: () => import('@/views/sources/payors/SourcesPayorsIndex.vue'),
                                    heading: () => import('@/views/sources/payors/SourcesPayorsIndexHeading.vue'),
                                    modalCreate: () => import('@/views/sources/payors/SourcesPayorsCreate.vue'),
                                },
                                meta: {
                                    title: 'Payors',
                                },
                            },
                            {
                                path: ':payorId',
                                components: {
                                    default: () => import('@/views/sources/payors/SourcesPayorsRecord.vue'),
                                    heading: () => import('@/views/sources/payors/SourcesPayorsRecordHeading.vue'),
                                    modalEdit: () => import('@/views/sources/payors/SourcesPayorsRecordEdit.vue'),
                                    modalComment: () => import('@/views/comments/ViewCommentsCreate.vue'),
                                    modalCommentEdit: () => import('@/views/comments/ViewCommentsEdit.vue'),
                                    modalCommentDelete: () => import('@/views/comments/ViewCommentsDelete.vue'),
                                    modalDelete: () => import('@/views/sources/payors/SourcesPayorsRecordDelete.vue'),
                                    modalCreateDirectory: () =>
                                        import('@/views/sources/payors/SourcesPayorsDirectoryCreate.vue'),
                                    modalEditDirectory: () =>
                                        import('@/views/sources/payors/SourcesPayorsDirectoryEdit.vue'),
                                    modalDeleteDirectory: () =>
                                        import('@/views/sources/payors/SourcesPayorsDirectoryDelete.vue'),
                                    modalIndexCreate: () =>
                                        import('@/views/sources/payors/SourcesPayorsIndexCreate.vue'),
                                    modalIndexEdit: () => import('@/views/sources/payors/SourcesPayorsIndexEdit.vue'),
                                    modalIndexDelete: () =>
                                        import('@/views/sources/payors/SourcesPayorsIndexDelete.vue'),
                                    modalCustomFiles: () =>
                                        import('@/views/sources/payors/SourcesPayorsIndexCustomFiles.vue'),
                                    modalIndexRunReprocess: () =>
                                        import('@/views/sources/payors/SourcesPayorsIndexRunReprocess.vue'),
                                    modalIndexTemplate: () => import('@/views/index-template/IndexTemplateEdit.vue'),
                                },
                                meta: {
                                    title: 'Payor Details',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'sources-payors-record',
                                        redirect: {
                                            name: 'sources-payors-record-comments',
                                        },
                                    },
                                    {
                                        path: 'comments',
                                        name: 'sources-payors-record-comments',
                                        component: () =>
                                            import('@/views/sources/payors/SourcesPayorsRecordComments.vue'),
                                    },
                                    {
                                        path: 'index/:indexId/run/:runId',
                                        component: () =>
                                            import('@/views/sources/payors/SourcesPayorsRecordResults.vue'),
                                        children: [
                                            {
                                                path: '',
                                                name: 'sources-payors-record-results',
                                                redirect: {
                                                    name: 'sources-payors-record-files',
                                                },
                                            },
                                            {
                                                path: 'files',
                                                name: 'sources-payors-record-files',
                                                components: {
                                                    default: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsFiles.vue'
                                                        ),
                                                    count: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsFilesCount.vue'
                                                        ),
                                                    search: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsFilesSearch.vue'
                                                        ),
                                                    searchFilters: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsFilesSearchFilters.vue'
                                                        ),
                                                    modalExtractFiles: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsFilesExtractor.vue'
                                                        ),
                                                },
                                            },
                                            {
                                                path: 'reporting-plans',
                                                name: 'sources-payors-record-reporting-plans',
                                                components: {
                                                    default: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsReportingPlans.vue'
                                                        ),
                                                    count: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsReportingPlansCount.vue'
                                                        ),
                                                    search: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsReportingPlansSearch.vue'
                                                        ),
                                                    searchFilters: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsReportingPlansSearchFilters.vue'
                                                        ),
                                                    modalExtractReportingPlans: () =>
                                                        import(
                                                            '@/views/sources/payors/SourcesPayorsRecordResultsReportingPlansExtractor.vue'
                                                        ),
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'health-systems',
                        component: () => import('@/views/sources/health-systems/SourcesHealthSystemsRouter.vue'),
                        children: [
                            {
                                path: '',
                                name: 'sources-health-systems-index',
                                components: {
                                    default: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsIndex.vue'),
                                    heading: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsIndexHeading.vue'),
                                    modalCreate: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsCreate.vue'),
                                },
                                meta: {
                                    title: 'Health Systems',
                                },
                            },
                            {
                                path: ':healthSystemId',
                                components: {
                                    default: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsRecord.vue'),
                                    heading: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsRecordHeading.vue'),
                                    modalEdit: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsRecordEdit.vue'),
                                    modalComment: () => import('@/views/comments/ViewCommentsCreate.vue'),
                                    modalCommentEdit: () => import('@/views/comments/ViewCommentsEdit.vue'),
                                    modalCommentDelete: () => import('@/views/comments/ViewCommentsDelete.vue'),
                                    modalDelete: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsRecordDelete.vue'),
                                    modalCreateDirectory: () =>
                                        import(
                                            '@/views/sources/health-systems/SourcesHealthSystemsDirectoryCreate.vue'
                                        ),
                                    modalEditDirectory: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsDirectoryEdit.vue'),
                                    modalDeleteDirectory: () =>
                                        import(
                                            '@/views/sources/health-systems/SourcesHealthSystemsDirectoryDelete.vue'
                                        ),
                                    modalIndexCreate: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsIndexCreate.vue'),
                                    modalIndexEdit: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsIndexEdit.vue'),
                                    modalIndexDelete: () =>
                                        import('@/views/sources/health-systems/SourcesHealthSystemsIndexDelete.vue'),
                                    modalCustomFiles: () =>
                                        import(
                                            '@/views/sources/health-systems/SourcesHealthSystemsIndexCustomFiles.vue'
                                        ),
                                    modalIndexRunReprocess: () =>
                                        import(
                                            '@/views/sources/health-systems/SourcesHealthSystemsIndexRunReprocess.vue'
                                        ),
                                    modalIndexTemplate: () => import('@/views/index-template/IndexTemplateEdit.vue'),
                                },
                                meta: {
                                    title: 'Health System Details',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'sources-health-systems-record',
                                        redirect: {
                                            name: 'sources-health-systems-record-comments',
                                        },
                                    },
                                    {
                                        path: 'comments',
                                        name: 'sources-health-systems-record-comments',
                                        component: () =>
                                            import(
                                                '@/views/sources/health-systems/SourcesHealthSystemsRecordComments.vue'
                                            ),
                                    },
                                    {
                                        path: 'index/:indexId/run/:runId',
                                        component: () =>
                                            import(
                                                '@/views/sources/health-systems/SourcesHealthSystemsRecordResults.vue'
                                            ),
                                        children: [
                                            {
                                                path: '',
                                                name: 'sources-health-systems-record-results',
                                                redirect: {
                                                    name: 'sources-health-systems-record-files',
                                                },
                                            },
                                            {
                                                path: 'files',
                                                name: 'sources-health-systems-record-files',
                                                components: {
                                                    default: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsFiles.vue'
                                                        ),
                                                    count: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsFilesCount.vue'
                                                        ),
                                                    search: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsFilesSearch.vue'
                                                        ),
                                                    searchFilters: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsFilesSearchFilters.vue'
                                                        ),
                                                    modalExtractFiles: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsFilesExtractor.vue'
                                                        ),
                                                },
                                            },
                                            {
                                                path: 'locations',
                                                name: 'sources-health-systems-record-locations',
                                                components: {
                                                    default: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsLocations.vue'
                                                        ),
                                                    count: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsLocationsCount.vue'
                                                        ),
                                                    search: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsLocationsSearch.vue'
                                                        ),
                                                    searchFilters: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsLocationsSearchFilters.vue'
                                                        ),
                                                    modalExtractReportingPlans: () =>
                                                        import(
                                                            '@/views/sources/health-systems/SourcesHealthSystemsRecordResultsLocationsExtractor.vue'
                                                        ),
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'signal',
                components: {
                    default: () => import('@/views/Signal/Views/ViewSignalRouter.vue'),
                },
                children: [
                    {
                        path: 'search',
                        components: {
                            default: () => import('@/views/Signal/Views/Search/ViewSignalSearchRouter.vue'),
                        },
                        children: [
                            {
                                path: ':signalId?',
                                components: {
                                    default: () => import('@/views/Signal/Views/Search/ViewSignalSearchIndex.vue'),
                                },
                                name: 'signal-search',
                                meta: {
                                    title: 'Signal',
                                },
                            },
                            {
                                path: '',
                                redirect: {
                                    name: 'signal',
                                },
                            },
                        ],
                    },
                    {
                        path: 'market-benchmarks',
                        components: {
                            default: () =>
                                import('@/views/Signal/Views/MarketBenchmarks/ViewSignalMarketBenchmarksRouter.vue'),
                        },
                        children: [
                            {
                                path: ':signalId?',
                                components: {
                                    default: () =>
                                        import(
                                            '@/views/Signal/Views/MarketBenchmarks/ViewSignalMarketBenchmarksIndex.vue'
                                        ),
                                },
                                name: 'signal-market-benchmarks',
                                meta: {
                                    title: 'Signal',
                                },
                            },
                            {
                                path: '',
                                redirect: {
                                    name: 'signal-market-benchmarks',
                                },
                            },
                        ],
                    },

                    {
                        path: 'peer-benchmarks',
                        components: {
                            default: () =>
                                import('@/views/Signal/Views/PeerBenchmarks/ViewSignalPeerBenchmarksRouter.vue'),
                        },
                        children: [
                            {
                                path: ':signalId?',
                                components: {
                                    default: () =>
                                        import('@/views/Signal/Views/PeerBenchmarks/ViewSignalPeerBenchmarksIndex.vue'),
                                },
                                name: 'signal-peer-benchmarks',
                                meta: {
                                    title: 'Signal',
                                },
                            },
                            {
                                path: '',
                                redirect: {
                                    name: 'signal-peer-benchmarks',
                                },
                            },
                        ],
                    },

                    {
                        path: '',
                        components: {
                            default: () => import('@/views/Signal/Views/ViewSignalIndex.vue'),
                        },
                        name: 'signal',
                    },

                    // REDIRECT old signal url without type slugs
                    {
                        path: ':signalId',
                        redirect: (to) => ({
                            name: 'signal-search',
                            params: { ...to.params },
                        }),
                    },
                ],
            },

            // Redirect for old /rate-search url
            {
                path: 'rate-search',
                redirect: {
                    name: 'signal',
                },
            },

            {
                path: 'amplitude',
                component: () => import('@/views/Amplitude/ViewAmplitudeRouter.vue'),
                meta: { title: 'Amplitude' },
                children: [
                    {
                        path: '',
                        components: {
                            default: () => import('@/views/Amplitude/Index/ViewAmplitudeIndex.vue'),
                            heading: () => import('@/views/Amplitude/Index/ViewAmplitudeIndexHeading.vue'),
                        },
                        name: 'amplitude-index',
                        meta: {
                            title: 'Amplitude',
                        },
                    },
                ],
            },

            {
                path: '/market-share',
                component: MarketShareRouter,
                meta: { title: 'Market Share' },
                children: [
                    {
                        path: '',
                        name: 'market-share',
                        component: MarketShareIndex,
                    },
                ],
            },

            // Deprecited Ananlytics route -> price-intelligence
            {
                path: 'analytics',
                meta: { title: 'Analytics' },
                children: [
                    {
                        path: '',
                        name: 'analytics',
                        redirect: {
                            name: 'pi-index',
                        },
                    },
                    {
                        path: 'payer',
                        meta: {
                            title: 'Payer Reimbursements',
                        },
                        redirect: {
                            name: 'pi-index',
                        },
                    },
                ],
            },

            {
                path: 'price-intelligence',
                children: [
                    {
                        path: '',
                        name: 'pi-index',
                        redirect: {
                            name: 'amplitude-index',
                        },
                    },
                    {
                        path: 'payer-contracts',
                        name: 'pi-payer-contracts',
                        redirect: {
                            name: 'amplitude-index',
                        },
                    },
                ],
            },
            {
                path: 'help',
                component: () => import('@/views/ViewHelp.vue'),
                meta: { title: 'Help' },
            },
            {
                path: 'dashboards',
                component: DashboardsRouter,
                meta: { title: 'Dashboards' },
                children: [
                    {
                        name: 'dashboards',
                        path: '',
                        component: DashboardsIndex,
                    },
                    {
                        path: 'radiology',
                        component: RadiologyDashboard,
                        meta: {
                            title: 'Radiology Dashboard',
                        },
                    },
                    {
                        path: 'behavioral-next',
                        name: 'behavioral-next',
                        component: () => import('@/views/dashboards/BHDashboardNext.vue'),
                        meta: {
                            title: 'Behavioral Health Dash',
                        },
                    },
                    {
                        path: 'behavioral-compare',
                        name: 'behavioral-compare',
                        component: () => import('@/views/dashboards/BHDashboardCompare.vue'),
                        meta: {
                            title: 'Behavioral Health Comparison Dash',
                        },
                    },
                ],
            },
            {
                path: 'networks',
                name: 'networks',
                component: () => import('@/views/networks/ViewNetworks.vue'),
                meta: { title: 'Available Networks' },
            },
        ],
    },

    { path: '/:path(.*)', component: () => import('@/views/ViewNotFound.vue') },
]
